import * as poseDetection from '@tensorflow-models/pose-detection';
import { RefObject } from 'react';
import Canvas from './canvas';
import Webcam from 'react-webcam';

const bodyMap = {
  nose: 0,
  left_eye: 1,
  right_eye: 2,
  left_ear: 3,
  right_ear: 4,
  left_shoulder: 5,
  right_shoulder: 6,
  left_elbow: 7,
  right_elbow: 8,
  left_wrist: 9,
  right_wrist: 10,
  left_hip: 11,
  right_hip: 12,
  left_knee: 13,
  right_knee: 14,
  left_ankle: 15,
  right_ankle: 16,
};

export function Tracking(
  canvasRef: RefObject<HTMLCanvasElement>,
  webcamRef: RefObject<Webcam>,
  detector: poseDetection.PoseDetector,
  setRepCount: React.Dispatch<React.SetStateAction<number>>
) {
  // Default the user's position to up, then we'll count a rep once the user goes down then
  // up again
  let repIsUp = true;

  // POSES
  async function getPoses() {
    // Get video properties
    if (webcamRef.current != null) {
      const video = webcamRef.current?.video;
      const videoWidth = video!.videoWidth;
      const videoHeight = video!.videoHeight;

      let poses = await detector!.estimatePoses(video!);
      requestAnimationFrame(async () => {
        await getPoses();
      });

      // Draw canvas
      Canvas(canvasRef).drawCanvas(poses, videoWidth, videoHeight, canvasRef);

      // Get rep info
      if (poses[0] != undefined) {
        const exercise = detectExercise();
        return countReps(exercise, poses);
      }
    }
  }

  function detectExercise() {
    return 'pushups';
  }

  function countReps(exercise: string, poses: poseDetection.Pose[]) {
    // Check if the athlete's body is in the screen
    // For pushups, this requires: nose, wrists, elbows, and shoulders
    // const bodyInScreen = true;
    // if (!bodyInScreen) {
    //   return "Body isn't in screen...reposition!";
    // }

    const keypoints = poses[0]['keypoints'];

    // We'll make the algorithm more accurate over time. For now, this will do:
    // Track the number of times the athlete transitions from rising to falling.
    // Each time the user goes from rising, to falling, then rising again, count 1 rep

    // Base form: the athlete's wrists must be below their elbows and shoulders
    // in order for the rep to count
    const leftShoulder = keypoints[bodyMap['left_shoulder']];
    const rightShoulder = keypoints[bodyMap['right_shoulder']];
    const leftElbow = keypoints[bodyMap['left_elbow']];
    const rightElbow = keypoints[bodyMap['right_elbow']];
    const leftWrist = keypoints[bodyMap['left_wrist']];
    const rightWrist = keypoints[bodyMap['right_wrist']];

    const wristsBelowElbows =
      leftWrist['y'] >= leftElbow['y'] && rightWrist['y'] >= rightElbow['y'];
    const wristsBelowShoulders =
      leftWrist['y'] >= leftShoulder['y'] &&
      rightWrist['y'] >= rightShoulder['y'];
    const baseForm = wristsBelowElbows && wristsBelowShoulders;

    // Rising to falling: the athlete transitions from rising to falling when the angle
    // between their shoulder-elbow-wrist is less than 90 degrees and their nose dips below
    // their elbow
    // Falling to rising: the athlete transitions from falling to rising when the angle
    // between their shoulder-elbow-wrist is greater than 135 degrees and their nose rises
    // above their elbow

    const leftElbowAngle = calculateAngle(leftWrist, leftElbow, leftShoulder);
    const rightElbowAngle = calculateAngle(
      rightWrist,
      rightElbow,
      rightShoulder
    );

    const nose = keypoints[bodyMap['nose']];
    const noseBelowElbows =
      nose['y'] >= leftElbow['y'] && nose['y'] >= rightElbow['y'];

    const down =
      leftElbowAngle <= 110 &&
      rightElbowAngle <= 110 &&
      // noseBelowElbows &&
      baseForm;

    const up =
      leftElbowAngle >= 135 &&
      rightElbowAngle >= 135 &&
      // !noseBelowElbows &&
      baseForm;

    determineIfAthleteCompletedRep(repIsUp, up, down);

    return {
      down,
      up,
      repStatus: down,
    };
  }

  function determineIfAthleteCompletedRep(
    athleteWasUp: boolean,
    athleteIsUp: boolean,
    athleteIsDown: boolean
  ) {
    const statusChanged =
      (athleteIsUp && !athleteWasUp) || (athleteIsDown && athleteWasUp);

    if (statusChanged) {
      if (athleteIsUp) {
        repIsUp = true;
        setRepCount!((current) => current + 1);
      } else if (athleteIsDown) {
        repIsUp = false;
      }
    }
    return;
  }

  function calculateLineLength(p1: any, p2: any) {
    return Math.sqrt(
      Math.pow(p1['x'] - p2['x'], 2) + Math.pow(p1['y'] - p2['y'], 2)
    );
  }

  function calculateAngle(p1: any, p2: any, p3: any) {
    const lineLength12 = calculateLineLength(p1, p2);
    const lineLength13 = calculateLineLength(p1, p3);
    const lineLength23 = calculateLineLength(p2, p3);

    return (
      (Math.acos(
        (Math.pow(lineLength12, 2) +
          Math.pow(lineLength23, 2) -
          Math.pow(lineLength13, 2)) /
          (2 * lineLength12 * lineLength23)
      ) *
        180) /
      Math.PI
    );
  }

  return {
    getPoses,
  };
}

export default Tracking;
