import React, { useEffect, useRef, useState, RefObject } from 'react';
import { LoggedInAthlete } from './coach';
import PrimaryText from './primaryText';

interface InactiveProps {
  athlete: LoggedInAthlete | undefined;
  loggedIn: boolean;
  setPageState: React.Dispatch<React.SetStateAction<string>>;
}

const Inactive = ({ athlete, loggedIn, setPageState }: InactiveProps) => {
  const handleStartWebcam = () => {
    setPageState('LOADING');
  };

  return (
    <>
      {/* Text */}
      <PrimaryText loggedIn={loggedIn} athlete={athlete} />
      {/* Start button */}
      <button
        type='button'
        className='general-button-properties start-button'
        onClick={handleStartWebcam}
      >
        Start workout
      </button>
    </>
  );
};

export default Inactive;
