import { RefObject } from 'react';
import * as poseDetection from '@tensorflow-models/pose-detection';

const Canvas = (canvasRef: RefObject<HTMLCanvasElement>) => {
  function drawKeypoint(keypoint: poseDetection.Keypoint) {
    const ctx = canvasRef.current?.getContext('2d');
    // If score is null, just show the keypoint.
    const confidence = keypoint.score != null ? keypoint.score : 1;
    const scoreThreshold = 0.3 || 0;

    if (confidence >= scoreThreshold) {
      const circle = new Path2D();
      circle.arc(keypoint.x, keypoint.y, 4, 0, 2 * Math.PI);
      ctx!.fill(circle);
      ctx!.stroke(circle);
    }
  }

  function drawKeypoints(keypoints: poseDetection.Keypoint[]) {
    const ctx = canvasRef.current?.getContext('2d');
    const keypointInd = poseDetection.util.getKeypointIndexBySide(
      poseDetection.SupportedModels.MoveNet
    );
    // ctx!.fillStyle = 'White';
    // ctx!.strokeStyle = 'White';
    ctx!.lineWidth = 2;

    //middle points will be white (just nose)
    for (const i of keypointInd.middle) {
      drawKeypoint(keypoints[i]);
    }
    //left points will be green... note your actual left side (technically right side when looking at video)
    ctx!.fillStyle = '#FBE8A6';
    for (const i of keypointInd.left) {
      drawKeypoint(keypoints[i]);
    }
    //right points will be orange... note your actual right side (technically left side when looking at video)
    ctx!.fillStyle = '#91CCF4';
    for (const i of keypointInd.right) {
      drawKeypoint(keypoints[i]);
    }
  }

  function drawSkeleton(keypoints: poseDetection.Keypoint[]) {
    const ctx = canvasRef.current?.getContext('2d');
    // ctx!.fillStyle = 'White';
    // ctx!.strokeStyle = 'White';
    ctx!.lineWidth = 2;

    poseDetection.util
      .getAdjacentPairs(poseDetection.SupportedModels.MoveNet)
      .forEach(([i, j]) => {
        const kp1 = keypoints[i];
        const kp2 = keypoints[j];
        const firstX = kp1.x;
        const firstY = kp1.y;
        const secondX = kp2.x;
        const secondY = kp2.y;
        const name = kp1.name! + kp2.name;
        const adjacentPairAngle = Math.abs(
          (Math.atan((firstY - secondY) / (firstX - secondX)) * 180) / Math.PI
        );

        // If score is null, just show the keypoint.
        const score1 = kp1.score != null ? kp1.score : 1;
        const score2 = kp2.score != null ? kp2.score : 1;
        const scoreThreshold = 0.2;

        if (score1 >= scoreThreshold && score2 >= scoreThreshold) {
          ctx!.beginPath();
          ctx!.moveTo(kp1.x, kp1.y);
          ctx!.lineTo(kp2.x, kp2.y);
          ctx!.stroke();
        }
      });
  }

  function drawCanvas(
    poses: poseDetection.Pose[],
    videoWidth: number,
    videoHeight: number,
    canvasRef: RefObject<HTMLCanvasElement>
  ) {
    if (canvasRef.current != null) {
      canvasRef.current.width = videoWidth;
      canvasRef.current.height = videoHeight;
    }

    if (poses[0] != null) {
      drawKeypoints(poses[0].keypoints);
      drawSkeleton(poses[0].keypoints);
    }
  }

  return {
    drawCanvas,
  };
};

export default Canvas;
