import { UseMutateAsyncFunction, useQuery, useQueryClient } from 'react-query';
import { useEffect, useMemo } from 'react';
import {
  Signup as SignupRoute,
  Login as LoginRoute,
  CheckLogin as CheckLoginRoute,
} from '../api/routes';
import { Athlete } from '../types/types';
import axios, { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import useReps from './useReps';
const { handleConnectAthleteToReps } = useReps();

export interface AthleteSignup {
  athlete: Athlete;
  repsId: string;
}

interface AthleteLoginDto extends Omit<Athlete, 'firstName' | 'lastName'> {
  repsId?: string;
}

interface UseAthleteInterface {
  handleSignup: any;
  handleLogin: any;
  handleCheckLogin: any;
}

const useAthlete = (): UseAthleteInterface => {
  const handleSignup = async ({
    athlete,
    repsId,
  }: AthleteSignup): Promise<AxiosResponse<any>> => {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_SERVER_URL}${SignupRoute}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(athlete),
      withCredentials: true,
    };
    return await axios(config).then(async (athleteData: any) => {
      await handleLogin({
        email: athleteData.data.email,
        password: athlete.password,
        repsId,
      });
      return athleteData;
    });
  };

  const handleLogin = async (
    athleteLoginDto: AthleteLoginDto
  ): Promise<AxiosResponse<any>> => {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_SERVER_URL}${LoginRoute}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(athleteLoginDto),
      withCredentials: true,
    };
    return await axios(config).then(async (athleteData: any) => {
      // If repsId is included, it means we need to connect an athlete to a workout
      if (athleteLoginDto.repsId != undefined) {
        await handleConnectAthleteToReps({
          athlete: athleteData.data,
          repsId: athleteLoginDto.repsId,
        });
      }
      return athleteData;
    });
  };

  const handleCheckLogin = async (): Promise<AxiosResponse<any>> => {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_SERVER_URL}${CheckLoginRoute}`,
      headers: {
        Authorization: `Bearer ${Cookies.get('jwt')}`,
      },
      data: {},
      withCredentials: true,
    };
    return await axios(config);
  };

  return {
    handleSignup,
    handleLogin,
    handleCheckLogin,
  };
};

export default useAthlete;
