import React, { useEffect, useRef, useState, RefObject } from 'react';
import Webcam from 'react-webcam';
import * as poseDetection from '@tensorflow-models/pose-detection';

interface CountdownProps {
  setRepCount: React.Dispatch<React.SetStateAction<number>>;
  setPageState: React.Dispatch<React.SetStateAction<string>>;
}

const Countdown = ({ setRepCount, setPageState }: CountdownProps) => {
  // Handle the countdown if the tracker finished loading
  const [countdownActivated, setCountdownActivated] = useState<boolean>(true);
  const initialCountdownSeconds = 3;
  const [countdownSeconds, setCountdownSeconds] = useState<number>(
    initialCountdownSeconds
  );
  const intervalRef = useRef<NodeJS.Timer>();
  useEffect(() => {
    if (countdownActivated) {
      intervalRef.current = setInterval(() => {
        setCountdownSeconds((countdownSeconds) => countdownSeconds - 1);
      }, 1000);
    }
  }, []);
  useEffect(() => {
    if (countdownSeconds == 0) {
      clearInterval(intervalRef.current);
      setCountdownActivated(false);
      setRepCount(0);
      setPageState('ACTIVE');
    }
  }, [countdownSeconds]);

  return (
    <>
      {/* Countdown Timer */}
      <button
        type='button'
        className='general-button-properties loading-button'
      >
        {countdownSeconds > 0
          ? `Starting in ${countdownSeconds}`
          : 'Starting now!'}
      </button>
    </>
  );
};

export default Countdown;
