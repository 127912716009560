import React, { useEffect, useRef, useState, RefObject } from 'react';
import * as poseDetection from '@tensorflow-models/pose-detection';
import Webcam from 'react-webcam';
import Tracking from './tracking';

interface LoadingProps {}

const Loading = ({}: LoadingProps) => {
  // Handle generating the correct gif depending on the screen size
  const gifPath =
    window.innerWidth >= 850 ? '/pushups-desktop.gif' : '/pushups-mobile.gif';

  return (
    <>
      <img className='loading-image' src={gifPath} />
      <p className='general-text-properties loading-text'>
        Get your whole body in the screen when the tracker loads
      </p>
      <button
        type='button'
        className='general-button-properties loading-button'
      >
        Loading tracker
      </button>
    </>
  );
};

export default Loading;
