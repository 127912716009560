import React, { useEffect, useRef, useState, RefObject } from 'react';
import Webcam from 'react-webcam';
import * as poseDetection from '@tensorflow-models/pose-detection';
import '@tensorflow/tfjs-backend-webgl';
import './styles.scss';
import useAthlete from '../../hooks/useAthlete';
import Inactive from './inactive';
import Loading from './loading';
import Active from './active';
import Countdown from './countdown';
import Tracking from './tracking';

export interface LoggedInAthlete {
  id?: string;
  firstName: string;
  lastName?: string;
  email?: string;
  joined?: Date;
}

const Coach = () => {
  // Check if the athlete is logged in
  const { handleCheckLogin } = useAthlete();
  const [athlete, setAthlete] = useState<LoggedInAthlete>();
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  useEffect(() => {
    handleCheckLogin()
      .then((res: any) => {
        setAthlete(res.data.athlete);
        setLoggedIn(true);
      })
      .catch((e: any) => {
        console.log(e);
        setLoggedIn(false);
      });
  }, []);

  // Reference for the webcam
  const webcamRef = useRef<Webcam>(null);

  // Instantiate the detector
  const [detector, setDetector] = useState<poseDetection.PoseDetector>();
  async function init() {
    const detectorConfig = {
      modelType: poseDetection.movenet.modelType.SINGLEPOSE_LIGHTNING,
    };
    const detector = await poseDetection.createDetector(
      poseDetection.SupportedModels.MoveNet,
      detectorConfig
    );
    setDetector(detector);
  }

  useEffect(() => {
    init();
    return () => {
      init();
    };
  }, []);

  // CANVAS
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current != null) {
      console.log(canvasRef);
      canvasRef.current.focus();
    }
  }, []);

  const [pageState, setPageState] = useState<string>('INACTIVE');

  const [repCount, setRepCount] = useState<number>(0);

  const startCountdown = () => {
    setPageState('COUNTDOWN');
    requestAnimationFrame(async () => {
      await Tracking(canvasRef, webcamRef, detector!, setRepCount).getPoses();
    });
  };

  return (
    <div className='full-screen'>
      {/* Inactive */}
      {pageState == 'INACTIVE' && (
        <Inactive
          athlete={athlete}
          loggedIn={loggedIn}
          setPageState={setPageState}
        />
      )}
      {/* Loading */}
      {pageState == 'LOADING' && <Loading />}
      {/* Countdown */}
      {pageState == 'COUNTDOWN' && (
        <Countdown setRepCount={setRepCount} setPageState={setPageState} />
      )}
      {/* Active */}
      {pageState == 'ACTIVE' && (
        <Active
          athlete={athlete}
          loggedIn={loggedIn}
          repCount={repCount}
          setPageState={setPageState}
        />
      )}
      {/* Video canvas */}
      {pageState != 'INACTIVE' && (
        <>
          <Webcam
            id='webcam'
            ref={webcamRef}
            className={
              pageState == 'LOADING' ? 'canvas loading-canvas' : 'canvas'
            }
            onLoadedData={startCountdown}
            playsInline={true}
          />
          <canvas id='canvas' ref={canvasRef} className='canvas' />
        </>
      )}
    </div>
  );
};

export default Coach;
