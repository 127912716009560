import { useEffect, useState } from 'react';
import useAthlete from '../../hooks/useAthlete';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router';

import './styles.scss';
import { RoutePaths } from '../Core/routes';
import { useSearchParams } from 'react-router-dom';

const Login = () => {
  const { handleLogin } = useAthlete();
  const [errorMessage, setErrorMessage] = useState<string>();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [repsId, setRepsId] = useState<string>();
  useEffect(() => {
    if (searchParams.get('repsId') != null) {
      setRepsId(searchParams.get('repsId') || undefined);
    }
  }, []);

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('Email must be valid')
      .required('Email is required'),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters'),
  });

  return (
    <div className='container'>
      <div className='content'>
        {!errorMessage && (
          <h1 className='header'>Log in to your Fitball account</h1>
        )}
        {errorMessage && (
          <h1 className='header error-message'>
            We couldn't find your account - try again!
          </h1>
        )}
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={(values) => {
            const athlete = {
              email: values.email,
              password: values.password,
              repsId,
            };
            handleLogin(athlete)
              .then(() => navigate(RoutePaths.COACH))
              .catch((err: any) => setErrorMessage(err.response.data.message));
          }}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group className='mb-3'>
                <Form.Control
                  id='email'
                  name='email'
                  type='email'
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder='Email'
                  className={
                    touched.email && errors.email ? 'error' : undefined
                  }
                ></Form.Control>
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Control
                  id='password'
                  name='password'
                  type='password'
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder='Password'
                  className={
                    touched.password && errors.password ? 'error' : undefined
                  }
                ></Form.Control>
              </Form.Group>
              <button className='submit-button' type='submit'>
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
      <p className='redirect-text'>
        <a className='link' href={RoutePaths.SIGNUP}>
          Click here to sign up for an account
        </a>
      </p>
    </div>
  );
};

export default Login;
