import React, { useEffect, useRef, useState, RefObject } from 'react';

interface TimerProps {
  time: number;
  setTime: React.Dispatch<React.SetStateAction<number>>;
}

const Timer = ({ time, setTime }: TimerProps) => {
  const intervalRef = useRef<NodeJS.Timer>();
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTime((time) => time + 1);
    }, 1000);
  }, []);

  const displayTime = (time: number) => {
    if (time < 60) {
      return time;
    } else {
      const minutes = Math.floor(time / 60);
      const seconds = String(time % 60).padStart(2, '0');
      return `${minutes}:${seconds}`;
    }
  };

  return (
    <>
      <button type='button' className='data time'>
        Time: {displayTime(time)}
      </button>
    </>
  );
};

export default Timer;
