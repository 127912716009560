import React, { useEffect, useRef, useState, RefObject } from 'react';

import './styles.scss';
import useAthlete from '../../hooks/useAthlete';
import useReps from '../../hooks/useReps';
import { useSearchParams } from 'react-router-dom';
import { LoggedInAthlete } from './coach';
import { Reps } from '../../types/types';

interface PrimaryTextProps {
  loggedIn: boolean;
  athlete: LoggedInAthlete | undefined;
}

const PrimaryText: React.FC<PrimaryTextProps> = ({ loggedIn, athlete }) => {
  const { handleFetchBest, handleFetchChallenge } = useReps();
  const [hasSavedReps, setHasSavedReps] = useState<boolean>(false);
  const [bestReps, setBestReps] = useState<Reps>();
  const [attemptedFetchReps, setAttemptedFetchReps] = useState<boolean>();

  const [challenge, setChallenge] = useState<boolean>(false);
  const [challengeInfo, setChallengeInfo] = useState<Reps>();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get('challengeId') != null) {
      handleFetchChallenge(searchParams.get('challengeId'))
        .then((challenge: any) => {
          setChallengeInfo(challenge.data);
        })
        .catch(() => '');
      setChallenge(true);
    }
  }, []);

  useEffect(() => {
    if (loggedIn && !attemptedFetchReps) {
      handleFetchBest(athlete?.id)
        .then((reps: any) => {
          if (reps.data != '') {
            setBestReps(reps.data);
            setHasSavedReps(true);
          }
          setAttemptedFetchReps(true);
        })
        .catch((e: any) => e);
    }
  });

  return (
    <div>
      {/* If this is a challenge page, display the challenge text */}
      {challenge && (
        <p className='general-text-properties inactive-text'>
          <span className='yellow-background'>
            {challengeInfo?.athlete?.firstName}
          </span>{' '}
          did{' '}
          <span className='green-background'>
            {challengeInfo?.repCount} {challengeInfo?.exercise}
          </span>{' '}
          in{' '}
          <span className='orange-background'>
            {challengeInfo?.seconds}{' '}
            {challengeInfo?.seconds != 1 ? 'seconds' : 'second'}
          </span>
          . Beat them!
        </p>
      )}
      {/* If this isn't a challenge, display the athlete text
          There are 3 scenarions:
          (1) The athlete is not logged in
          (2) The athlete is logged in, but doesn't have any saved sets
          (3) The athlete is logged in and has saved sets
           */}
      {!challenge && !loggedIn && (
        <p className='general-text-properties inactive-text'>
          Hey <span className='yellow-background'>champ</span>
          {'\n\n'}Let's log some{' '}
          <span className='green-background'>pushups</span>!
        </p>
      )}
      {!challenge && loggedIn && !hasSavedReps && (
        <p className='general-text-properties inactive-text'>
          Hey <span className='yellow-background'>{athlete!.firstName}</span>
          {'\n\n'}Let's log some{' '}
          <span className='green-background'>pushups</span>!
        </p>
      )}
      {!challenge && loggedIn && hasSavedReps && (
        <p className='general-text-properties inactive-text'>
          Hey <span className='yellow-background'>{athlete!.firstName}</span>
          {'\n\n'}Your record is{' '}
          <span className='green-background'>
            {bestReps!.repCount} {bestReps!.exercise}
          </span>{' '}
          in{' '}
          <span className='orange-background'>
            {bestReps!.seconds} {bestReps!.seconds != 1 ? 'seconds' : 'second'}
          </span>
          . Beat that!
        </p>
      )}
    </div>
  );
};

export default PrimaryText;
