import { UseMutateAsyncFunction, useQuery, useQueryClient } from 'react-query';
import { useEffect, useMemo } from 'react';
import {
  SaveReps as SaveRepsRoute,
  BestReps as BestRepsRoute,
  Challenge as ChallengeRoute,
  ConnectAthleteToReps as ConnectAthleteToRepsRoute,
} from '../api/routes';
import { Athlete, Reps } from '../types/types';
import axios, { AxiosResponse } from 'axios';
import { AthleteSignup } from './useAthlete';

interface useRepsInterface {
  handleSaveReps: any;
  handleFetchBest: any;
  handleFetchChallenge: any;
  handleConnectAthleteToReps: any;
}

const useReps = (): useRepsInterface => {
  const handleSaveReps = async (reps: Reps): Promise<AxiosResponse<any>> => {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_SERVER_URL}${SaveRepsRoute}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(reps),
      withCredentials: true,
    };
    return await axios(config);
  };

  const handleFetchBest = async (
    athleteId: string
  ): Promise<AxiosResponse<any>> => {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_SERVER_URL}${BestRepsRoute(athleteId)}`,
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    };
    return await axios(config);
  };

  const handleFetchChallenge = async (
    repsId: string
  ): Promise<AxiosResponse<any>> => {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_SERVER_URL}${ChallengeRoute(repsId)}`,
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    };
    return await axios(config);
  };

  const handleConnectAthleteToReps = async ({
    athlete,
    repsId,
  }: AthleteSignup): Promise<AxiosResponse<any>> => {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_SERVER_URL}${ConnectAthleteToRepsRoute}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({ athlete, repsId }),
      withCredentials: true,
    };
    return await axios(config);
  };

  return {
    handleSaveReps,
    handleFetchBest,
    handleFetchChallenge,
    handleConnectAthleteToReps,
  };
};

export default useReps;
