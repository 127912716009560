import React from 'react';
import { Route, Routes as RouteSwitch } from 'react-router-dom';
import Coach from '../Coach/coach';
import Signup from '../Auth/signup';
import Login from '../Auth/login';
import ConnectAccount from '../Auth/connectAccount';

export const RoutePaths = {
  COACH: '/coach',
  SIGNUP: '/signup',
  LOGIN: '/login',
  CONNECTACCOUNT: '/connect-account',
};

const Routes: React.FC = () => {
  return (
    <RouteSwitch>
      <Route path='/' element={<Coach />} />
      <Route path={RoutePaths.COACH} element={<Coach />} />
      <Route path={RoutePaths.SIGNUP} element={<Signup />} />
      <Route path={RoutePaths.LOGIN} element={<Login />} />
      <Route path={RoutePaths.CONNECTACCOUNT} element={<ConnectAccount />} />
    </RouteSwitch>
  );
};

export default Routes;
