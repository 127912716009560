import React, { useEffect, useRef, useState, RefObject } from 'react';
import { useNavigate } from 'react-router-dom';
import Webcam from 'react-webcam';
import * as poseDetection from '@tensorflow-models/pose-detection';
import useReps from '../../hooks/useReps';
import { RoutePaths } from '../Core/routes';
import { LoggedInAthlete } from './coach';
import Timer from './timer';

interface ActiveProps {
  athlete: LoggedInAthlete | undefined;
  loggedIn: boolean;
  repCount: number;
  setPageState: React.Dispatch<React.SetStateAction<string>>;
}

const Active = ({ athlete, loggedIn, repCount, setPageState }: ActiveProps) => {
  // Navigator to change pages
  const navigate = useNavigate();

  // Method to save reps when the athlete finishes the workout
  const { handleSaveReps } = useReps();

  // Time
  const [time, setTime] = useState<number>(0);

  // If the user clicks the stop button, save the workout
  const handleStopWebcam = async () => {
    // Only save workouts if the repCount is at least 1
    if (repCount > 0) {
      await handleSaveReps({
        repsInfo: {
          exercise: 'pushups',
          repCount,
          seconds: time,
        },
        athleteId: loggedIn ? athlete!.id : null,
      }).then((reps: any) => {
        if (!loggedIn) {
          navigate(`${RoutePaths.CONNECTACCOUNT}?repsId=${reps.data.id}`);
        }
      });
    }
    setPageState('INACTIVE');
  };

  return (
    <>
      {/* Stop button */}
      <button
        type='button'
        className='general-button-properties stop-button'
        onClick={() => handleStopWebcam()}
      >
        Stop workout
      </button>
      {/* Timer */}
      <Timer time={time} setTime={setTime} />
      {/* Counter */}
      <button type='button' className='data counter'>
        Pushups: {repCount}
      </button>
    </>
  );
};

export default Active;
