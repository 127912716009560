import { useState, useEffect } from 'react';
import useAthlete from '../../hooks/useAthlete';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router';

import './styles.scss';
import { RoutePaths } from '../Core/routes';
import { useSearchParams } from 'react-router-dom';

const Signup = () => {
  const { handleSignup } = useAthlete();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [repsId, setRepsId] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get('repsId') != null) {
      setRepsId(searchParams.get('repsId') || undefined);
    }
  }, []);

  const validationSchema = yup.object().shape({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    email: yup
      .string()
      .email('Email must be valid')
      .required('Email is required'),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters'),
    confirmPassword: yup
      .string()
      .required('Password Confirmation is required')
      .oneOf([yup.ref('password')], "Your passwords don't match :)"),
  });

  return (
    <div className='container'>
      <div className='content'>
        {!errorMessage && (
          <h1 className='header'>Finish signing up for Fitball!</h1>
        )}
        {errorMessage && (
          <h1 className='header error-message'>
            Please sign up again - {errorMessage}
          </h1>
        )}
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
          }}
          onSubmit={(values) => {
            const athlete = {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              password: values.password,
            };

            handleSignup({ athlete, repsId })
              .then(() => navigate(RoutePaths.COACH))
              .catch((err: any) => setErrorMessage(err.response.data.message));
          }}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            status,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group className='mb-3'>
                <Form.Control
                  id='firstName'
                  name='firstName'
                  type='text'
                  value={values.firstName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder='First Name'
                  className={
                    touched.firstName && errors.firstName ? 'error' : undefined
                  }
                ></Form.Control>
                {touched.firstName && errors.firstName ? (
                  <div className='error-message'>{errors.firstName}</div>
                ) : null}
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Control
                  id='lastName'
                  name='lastName'
                  type='text'
                  value={values.lastName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder='Last Name'
                  className={
                    touched.lastName && errors.lastName ? 'error' : undefined
                  }
                ></Form.Control>
                {touched.lastName && errors.lastName ? (
                  <div className='error-message'>{errors.lastName}</div>
                ) : null}
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Control
                  id='email'
                  name='email'
                  type='email'
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder='Email'
                  className={
                    touched.email && errors.email ? 'error' : undefined
                  }
                ></Form.Control>
                {touched.email && errors.email ? (
                  <div className='error-message'>{errors.email}</div>
                ) : null}
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Control
                  id='password'
                  name='password'
                  type='password'
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder='Password'
                  className={
                    touched.password && errors.password ? 'error' : undefined
                  }
                ></Form.Control>
                {touched.password && errors.password ? (
                  <div className='error-message'>{errors.password}</div>
                ) : null}
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Control
                  id='confirmPassword'
                  name='confirmPassword'
                  type='password'
                  value={values.confirmPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder='Confirm Password'
                  className={
                    touched.confirmPassword && errors.confirmPassword
                      ? 'error'
                      : undefined
                  }
                ></Form.Control>
                {touched.confirmPassword && errors.confirmPassword ? (
                  <div className='error-message'>{errors.confirmPassword}</div>
                ) : null}
              </Form.Group>
              <button className='submit-button' type='submit'>
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
      <p className='redirect-text'>
        <a className='link' href={RoutePaths.LOGIN}>
          Click here to log in to your account
        </a>
      </p>
    </div>
  );
};

export default Signup;
