import useAthlete from '../hooks/useAthlete';
import { AthleteSignup } from '../hooks/useAthlete';

const Base = '/api';

export const Signup = `${Base}/athletes/signup`;
export const Login = `${Base}/athletes/login`;
export const CheckLogin = `${Base}/athletes/login/fetch`;
export const SaveReps = `${Base}/reps/save`;
export const BestReps = (athleteId: string) => `${Base}/reps/best/${athleteId}`;
export const Challenge = (repsId: string) => `${Base}/reps/${repsId}`;
export const ConnectAthleteToReps = `${Base}/reps/connect`;
