import { useEffect, useState } from 'react';
import { RoutePaths } from '../Core/routes';
import { useSearchParams } from 'react-router-dom';

import './styles.scss';
import useReps from '../../hooks/useReps';

const Login = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [repsId, setRepsId] = useState<string>();
  const [repCount, setRepCount] = useState<number>();
  const { handleFetchChallenge } = useReps();
  useEffect(() => {
    if (searchParams.get('repsId') != null) {
      setRepsId(searchParams.get('repsId') || undefined);
    }
  }, []);
  useEffect(() => {
    if (repCount == undefined) {
      handleFetchChallenge(repsId).then((reps: any) => {
        setRepCount(reps.data.repCount);
      });
    }
  });
  return (
    <div className='container'>
      <p className='primary-text'>
        You did{' '}
        <span className='yellow-background'>
          {repCount} rep
          {repCount != undefined && repCount > 1 ? 's' : ''}
        </span>{' '}
        - awesome job!
        {'\n\n'}
        <a
          className='signup-button'
          href={`${RoutePaths.SIGNUP}?repsId=${repsId}`}
        >
          Sign up
        </a>{' '}
        or{' '}
        <a
          className='signup-button'
          href={`${RoutePaths.LOGIN}?repsId=${repsId}`}
        >
          log in
        </a>{' '}
        to save your set + challenge your friends
      </p>
    </div>
  );
};

export default Login;
